import React from 'react'
import styled from 'styled-components';

const PrivacyPolicyWrapper = styled.div`
  background-color: white;
  max-width:1200px;
  margin:auto;
  padding-top:80px;
  @media (max-width: 1000px) {
    max-width:90%;
  }
`

function PrivacyPolicy() {
  return (
    

    <PrivacyPolicyWrapper >
      <h1 class="MainHeading-sc-12cokld-0 hXIKbn">Privacy Policy</h1>
      <p class="Text-sc-1j3qsx4-0 dGrxvY">last updated August 26, 2020</p>
      <h2>Introduction</h2>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">Thank you for choosing Kisstasy</p>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">At Kisstasy, protecting your private information is our priority and we are committed to being upfront about it. Kisstasy respects the privacy needs and concerns of our customers. We appreciate the trust you place in us when you choose to visit our websites, make use of our App and Services and we take that responsibility seriously.</p>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">This Privacy Policy explains how Auiodesires.com (“Kisstasy”, “we” or “us”) collects, uses, and discloses information about you when you access or use our websites, mobile application, and other online products and services (collectively, the “Services”), and when you contact our customer service team, engage with us on social media, or otherwise interact with us. </p>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our website homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the choices available to you.</p>
      <h2>Privacy Policy for Children</h2>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We do not intentionally gather Personal Data from users who are under the age of 18 or that are minors in the jurisdiction from which the Kisstasy website is accessed. If a user under the age of 18 submits Personal Data to Us and we learn that the Personal Data is the information of a user under the age of 18, we will attempt to delete the information as soon as possible. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably possible. </p>
      <h2>Collection of Information</h2>
      <h3>Information You Provide to Us</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We collect information when you register for an account, participate in interactive features (like submit a meditation rating), fill out a form or a survey, participate in a contest or promotion, make a purchase, communicate with us via social media sites, request customer support, or otherwise communicate with us. The information you may provide includes your name, email, password, goals, previous meditation experience, street address, payment method information, feedback and survey responses, and other information about you included within your messages to us. Some users also provide health-related information in connection with providing feedback or other messages to us, and we process that information consistent with the purpose for which it was provided.</p>
      <h3>Other Information About Your Use of the Services</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">When you use our Services, we collect the following information about you:</p>
      <ol>
          <li>Usage Information: Whenever you use our Services, we collect usage information, such as the sessions you use, videos you view or music you listen to, what screens or features you access, and other similar types of usage information.</li>
          <li>Transactional Information: When you make a purchase or return, we collect information about the transaction, such as product description, price, subscription or free trial expiration date, and time and date of the transaction.</li>
          <li>Log Information: We collect standard log files when you use our Services, which include the type of web browser you use, app version, access times and dates, pages viewed, your IP address, and the page you visited before navigating to our websites.</li>
          <li>Device Information: We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, device identifiers set by your device operating system, and mobile network information (like your connection type, carrier and region).</li>
          <li>Information we Generate: We generate some information about you based on other information we have collected. For example, like most platforms, we use your IP address to derive the approximate location of your device. We also use your first name to make an educated guess about your gender and use information about your activity to help determine the likelihood of you continuing to use our Services in the future (which we hope will be the case!).</li>
          <li>Information Collected by Cookies and Similar Tracking Technologies: We use different technologies to collect information, including cookies and web beacons.</li>
      </ol>
      <h3>Information We Collect from Other Sources</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We also obtain information about you from other sources, including transaction information from third-party app stores you use to install our app or purchase a subscription and name and contact information from third-party calendar services. Additionally, if you create or log into your account through a social media service account, we will have access to certain information from that account, such as your name and other account information, in accordance with the authorization procedures set by that social media service.</p>
      <h2>Use of Information</h2>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We use the information we collect to:</p>
      <ol>
          <li>Provide, maintain and improve our Services, and develop new products and service;</li>
          <li>Process transactions and fulfill orders;</li>
          <li>Send you transactional or relationship messages, such as receipts, account notifications, customer service responses, and other administrative messages;</li>
          <li>Communicate with you about products, services, and events offered by Kisstasy and others, request feedback, and send news, gifts or other information we think will be of interest to you;</li>
          <li>Monitor and analyze trends, usage, and activities in connection with our Services;</li>
          <li>Detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights and property of Kisstasy and others. If you feel your transaction was declined in error, please contact us for assistance at support@Kisstasy.com;</li>
          <li>Comply with the law, such as by processing transactional records for tax filings;</li>
          <li>Personalize your online experience and the advertisements you see on other platforms based on your preferences, interests, and browsing behavior; and</li>
          <li>Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards.</li>
      </ol>
      <h2>Sharing of Information</h2>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We do not sell, rent, or otherwise trade personal information collected about you through the Site, and that collected through the Services to others. The information that you provide to us is an important part of our business and we share your personal information only as described below:</p>
      <ol>
          <li>With companies and contractors that perform services for us, including email service providers, payment processors, fraud prevention vendors and other service providers;</li>
          <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements;</li>
          <li>If we believe your actions are inconsistent with our user agreements or policies, if we believe you have violated the law, or to protect the rights, property, and safety of Kisstasy or others;</li>
          <li>In connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition of all or a portion of our business by another company;</li>
          <li>Between and among Kisstasy and our current and future parents, affiliates, subsidiaries, and other companies under common control and ownership; and</li>
          <li>With your consent or at your direction. For instance, you may choose to share actions you take on our Services with third-party social media services via the integrated tools we provide via our Services. We also share aggregated or other information not subject to obligations under the data protection laws of your jurisdiction with third parties. For example, we sometimes share aggregate information with research organizations to help facilitate their research.</li>
      </ol>
      <h2>Advertising and Analytics Services Provided by Others</h2>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We allow others to provide analytics services and serve advertisements on our behalf across the web and in mobile applications. These entities use cookies, web beacons, device identifiers and other technologies to collect information about your use of the Services and other websites and online services, including your IP address, device identifiers, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, and conversion information. This information may be used by Kisstasy and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests on our Services and other websites and online services, and better understand your online activity. For more information about interest-based ads, or to opt out of having your web browsing information used for behavioral advertising purposes, please visit www.aboutads.info/choices. If you are in the EU, please visit www.youronlinechoices.eu/. Your device may also include a feature (“Limit Ad Tracking” on iOS or “Opt Out of Interest-Based Ads” or “Opt Out of Ads Personalization” on Android) that allows you to opt out of having certain information collected through apps used for behavioral advertising purposes.</p>
      <h2>Data Transfer</h2>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">Kisstasy is based in the United States and processes information in the United States, which may not provide equivalent levels of data protection as your home jurisdiction.</p>
      <h2>Your Choices</h2>
      <h3>Account Information</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">You may update certain account information you provide to us (such as your password, name and email address) by logging into your account or contacting us through our Help Center at support@Kisstasy.com. If you wish to disable your account, please contact us at support@Kisstasy.com, but note that we retain certain information when required or permitted by law. We also retain cached or archived copies of information about you for a certain period of time.</p>
      <h3>Promotional Communications</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">You may opt out of receiving promotional emails from Kisstasy by following the instructions in those emails or by logging into your account. If you opt out, we may still send you non-promotional emails, such as those about your account or our ongoing business relations.</p>
      <h3>Mobile Push Notifications/Alerts</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">With your consent, we send promotional and other push notifications to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.</p>
      <h3>Information for Individuals in Switzerland, UK and the EEA</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">EEA The sections below apply to you if you use our Services while in Switzerland, the United Kingdom or the European Economic Area (collectively, “Europe”).</p>
      <h3>Legal Basis for Processing</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">When we process your personal data we will only do so in the following situations:</p>
      <ol>
          <li>As necessary to perform our responsibilities under our contract with you (like processing payments and providing the products or services you have requested);</li>
          <li>When we have a legitimate interest in processing your personal data, including to communicate with you about changes to our Services, to help secure and improve our Services (including to prevent fraud) and to analyze use of our Services;</li>
          <li>As necessary to comply with our legal obligations; and</li>
          <li>When we have your consent to do so.</li>
      </ol>
      <h3>Data Retention</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We retain personal data for no longer than is necessary for the purposes for which it is processed, unless applicable law requires storage for a longer period of time.</p>
      <h3>Data Subject Requests</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">Subject to certain limits and conditions provided under law, you have the following rights:</p>
      <ol>
          <li>You have the right to access personal data we hold about you and to ask that your personal data be corrected, erased, or made available in a portable form.</li>
          <li>You also have the right to object to certain processing (like receiving direct marketing), or request that we restrict processing in certain circumstances (like to retain but not further process pending resolution of a claim)</li>
          <li>When we ask for your consent, you may withdraw that consent at any time.</li>
          <li>You have the right to file a complaint regarding our data protection practices with a supervisory authority. Please see this directory for contact details:directory. If you are in</li>
          <li>Switzerland, please visit this FDPIC site for contact details:FDPIC site</li>
      </ol>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">If you would like to exercise any of these rights and can’t do so directly via the Services or your device, you may contact us as indicated below.</p>
      <h3>Information for California Residents</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">This section provides additional disclosures required by the California Consumer Privacy Act (or “CCPA”). In the last 12 months, we collected the following categories of personal information: identifiers (such as name, contact information and device identifiers), internet or other electronic network activity information (such as browsing behavior), approximate geolocation data, inferences (such as approximate location or product interests) and other personal information (such as payment method information, user credentials or health-related information). For more details about the personal information we collect, including the categories of sources, please see the “Collection of Information” section above</p>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">We collect this information for the business and commercial purposes described in the “Use of Information” section above. We share this information with the categories of third parties described in the “Sharing of Information” section above. </p>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">Subject to certain limitations and exceptions, the CCPA provides California consumers the right to request to know more details about the categories and specific pieces of personal information, to delete their personal information, to opt out of any “sales” that may be occurring, and to not be discriminated against for exercising these rights. We do not “sell” the personal information we collect (and will not sell it in the future without providing a right to opt out). </p>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">California consumers may make a rights request by emailing us at support@Kisstasy.com We will verify your request by asking you to provide information that matches information we have on file about you. Consumers can also designate an authorized agent to exercise these rights on their behalf, but we will require proof that the person is authorized to act on your behalf and may also still ask you to verify your identity with us directly. </p>
      <h3>Contact Us</h3>
      <p class="Text-sc-1j3qsx4-0 hfKdPs">If you have any questions about this Privacy Policy, please contact us at: support@Kisstasy.com.</p>
    </PrivacyPolicyWrapper>


  )
}

export default PrivacyPolicy